import React from "react";
import "./Contact.scss";

import { LuPhone } from "react-icons/lu";
import { BsWhatsapp } from "react-icons/bs";
import { HiOutlineMail } from "react-icons/hi";
import { AiFillLinkedin } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { SlLocationPin } from "react-icons/sl";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact" className="wrapper section-margin">
      <div className="contact-title">{t("contactUsHeading")}</div>
      <div>{t("contact")}</div>
      <div className="contact-items">
        <div>
          <AiFillLinkedin size={65} color="#717171" />
          <div className="contact-sub-title">LinkedIn</div>
          <a
            href="https://www.linkedin.com/in/mathewanish/"
            target="_blank"
            rel="noreferrer"
          >
            Mr. Anish Mathew
          </a>
        </div>
        <div>
          <BsWhatsapp size={65} color="#717171" />
          <div className="contact-sub-title">WhatsApp</div>
          <div>
            <a
              href="http://wa.me/971564453432"
              target="_blank"
              rel="noreferrer"
            >
              +971 56 445 3432
            </a>
          </div>
        </div>
        <div>
          <LuPhone size={65} color="#717171" />
          <div className="contact-sub-title">{t("phone")}</div>
          <div>
            <a href="tel:+971564453432" target="_blank" rel="noreferrer">
              +971 56 445 3432
            </a>
            <a href="tel:+97143203144" target="_blank" rel="noreferrer">
              +971 43 203 144
            </a>
          </div>
        </div>
        <div>
          <HiOutlineMail size={65} color="#717171" />
          <div className="contact-sub-title">{t("email")}</div>
          <div>
            <a
              href="mailto:anish@digtlworld.com"
              target="_blank"
              rel="noreferrer"
            >
              anish@digtlworld.com
            </a>
          </div>
        </div>
      </div>

      <section className="locations">
        <SlLocationPin size={90} color="#717171" />
        <div>
          <span>Hong Kong</span>
          <span>Digtlworld Limited</span>
          <span>
            409, Beverly Commercial Center,
            <br /> 87-105, Chatham Road South,
            <br /> TSIM Shah Tsui KL
          </span>
        </div>
        <div>
          <span>UAE</span>
          <span>Digtlworld LLC</span>
          <span>
            1726 Tamani Art Offices <br />
            Business Bay Dubai
          </span>
        </div>
      </section>
    </section>
  );
};

export default Contact;

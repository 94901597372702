import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import "./Navbar.scss";
import { useTranslation } from "react-i18next";

const Navbar = ({ visible }) => {
  const [isNavItemVisible, setIsNavItemVisible] = useState(false);
  const toggleNavbarVisibility = () => {
    setIsNavItemVisible(!isNavItemVisible);
  };
  const { t } = useTranslation();

  return (
    <nav id="navbar" className={visible ? "navbar" : "navbar-not-visible"}>
      <h2 className="logo">DigtlWorld</h2>
      <ul className="list-of-menu-items hideOnMobile">
        <li>
          <a href="#brands">{t("navbarItem1")}</a>
        </li>
        <li>
          <a href="#products">{t("navbarItem2")}</a>
        </li>
        <li>
          <a href="#contact">{t("navbarItem3")}</a>
        </li>
      </ul>
      <span className="visible" onClick={toggleNavbarVisibility}>
        <GiHamburgerMenu size={20} style={{ color: "#0b132b" }} />
      </span>
      {isNavItemVisible && (
        <ul className="list-of-menu-items visible">
          <li onClick={toggleNavbarVisibility}>
            <a href="#brands">About</a>
          </li>
          <li onClick={toggleNavbarVisibility}>
            <a href="#products">Products</a>
          </li>
          <li onClick={toggleNavbarVisibility}>
            <a href="#contact">Contact Us</a>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;

import React, { useState } from "react";
import Slider from "react-slick";
import { images } from "../../images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./LatestArrivals.scss";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi";
import { useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";

const LatestArrivals = () => {
  const [imageIndex, setImageIndex] = useState(0);
  const matches = useMediaQuery("(max-width:768px)");
  const [sliderRef, setSliderRef] = useState(null);
  const { t } = useTranslation();

  const settings = {
    infinite: true,
    lazyLoad: true,
    slidesToShow: matches ? 2 : 4,
    centerPadding: 50,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => setImageIndex(next),
  };

  return (
    <section
      id="latestArrivals"
      className="latest-arrivals section-margin wrapper"
    >
      <div className="latest-arrivals-title">{t("latestArrivalsHeading")}</div>
      <div className="latest-arrival-items-and-descriptions">
        <Slider ref={setSliderRef} {...settings}>
          {images.map((img, idx) => (
            <div
              className={idx === imageIndex ? "slide activeSlide" : "slide"}
              key={img.id}
            >
              <img src={img.src} alt={img.alt} />
              <p>{t(img.descKey)}</p>
            </div>
          ))}
        </Slider>
      </div>

      <div className="arrows">
        <button className="left-arrow" onClick={sliderRef?.slickPrev}>
          <FiArrowLeft size={20} />
        </button>
        <button className="right-arrow" onClick={sliderRef?.slickNext}>
          <FiArrowRight size={20} />
        </button>
      </div>
    </section>
  );
};

export default LatestArrivals;

import React from "react";
import { Zoom } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

import BannerImage from "../../images/digitlworld.png";

import "./Banner.scss";

const Banner = () => {
  const { t } = useTranslation();
  return (
    <div id="banner" className="banner-parent wrapper">
      <Zoom triggerOnce>
        <div className="banner-caption">
          <span className="banner-caption-title">DigtlWorld</span>
          <span className="banner-description">{t("banner")}</span>
        </div>
        <img src={BannerImage} alt="Banner" className="banner-image" />
      </Zoom>
    </div>
  );
};

export default Banner;

import React from "react";
import "./Brands.scss";
import Apple from "../../images/Apple.png";
import Sony from "../../images/Sony.png";
import Dyson from "../../images/Dyson.png";
import Samsung from "../../images/samsung.png";
import { useTranslation } from "react-i18next";

const Brands = () => {
  const { t } = useTranslation();
  return (
    <section id="brands" className="brands wrapper">
      <div>
        <div className="brand-title">{t("topBrandsHeading")}</div>
        <div className="brand-logos">
          <img src={Apple} alt="Apple" />
          <img src={Sony} alt="Sony" />
          <img src={Samsung} alt="Samsung" />
          <img src={Dyson} alt="Dyson" />
        </div>
      </div>
    </section>
  );
};

export default Brands;

import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";

import { BsWhatsapp, BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { useTranslation } from "react-i18next";

import "./Footer.scss";

const options = ["English", "Arabic", "Chinese"];

const Footer = () => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const selectedLangIndex = options.indexOf(localStorage.i18nextLng);
  const [selectedIndex, setSelectedIndex] = React.useState(
    selectedLangIndex === -1 ? 0 : selectedLangIndex
  );
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    changeLanguage(options[index]);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (options[selectedIndex] === "Arabic") {
      document.body.setAttribute("data-dir", "rtl");
    } else {
      document.body.removeAttribute("data-dir");
    }
  }, [selectedIndex]);

  return (
    <footer className="footer section-margin" id="footer">
      <div className="social-icons-and-description">
        <span>DigtlWorld</span>
        <div className="social-icons">
          <a href="http://wa.me/971564453432" target="_blank" rel="noreferrer">
            <BsWhatsapp
              size={24}
              style={{ color: "#8da0a6" }}
              className="icon"
            />
          </a>
          <a
            href="https://www.linkedin.com/in/mathewanish/"
            target="_blank"
            rel="noreferrer"
          >
            <BsLinkedin
              size={24}
              style={{ color: "#8da0a6" }}
              className="icon"
            />
          </a>
          <a
            href="mailto:anish@digtlworld.com"
            target="_blank"
            rel="noreferrer"
          >
            <MdEmail size={30} style={{ color: "#8da0a6" }} className="icon" />
          </a>
        </div>
      </div>
      <div className="language-picker">
        <ButtonGroup
          variant="text"
          ref={anchorRef}
          aria-label="split button"
          color="inherit"
          className="language-picker-dropdown"
        >
          <Button onClick={handleToggle}>{options[selectedIndex]}</Button>
          <Button
            size="small"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <div className="copyright-text">{t("copyrightText")}</div>
    </footer>
  );
};

export default Footer;

import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Products.scss";
import { brandLogoRow1, brandLogoRow2, brandLogoRow3 } from "../../images";
import { useTranslation } from "react-i18next";

const Products = () => {
  const { t } = useTranslation();
  return (
    <div id="products" className="section-margin wrapper">
      <div className="products-title ">{t("brandsHeading")}</div>
      <section className="logoMarqueeSection products">
        <div className="container" id="logoMarqueeSection">
          <div className="default-content-container flex items-center">
            <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
              <div className="marquee">
                {brandLogoRow1.map((item) => (
                  <span key={item.id}>
                    <img
                      src={item.src}
                      alt={item.alt}
                      className="marqueelogo"
                    />
                  </span>
                ))}
              </div>
              <div className="marquee">
                {brandLogoRow1.map((item) => (
                  <span key={item.id}>
                    <img
                      src={item.src}
                      alt={item.alt}
                      className="marqueelogo"
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logoMarqueeSection products">
        <div className="container" id="logoMarqueeSection">
          <div className="default-content-container flex items-center">
            <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
              <div className="marquee marquee-row-2">
                {brandLogoRow2.map((item) => (
                  <span key={item.id}>
                    <img
                      src={item.src}
                      alt={item.alt}
                      className="marqueelogo"
                    />
                  </span>
                ))}
              </div>
              <div className="marquee marquee-row-2">
                {brandLogoRow2.map((item) => (
                  <span key={item.id}>
                    <img
                      src={item.src}
                      alt={item.alt}
                      className="marqueelogo"
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="logoMarqueeSection products">
        <div className="container" id="logoMarqueeSection">
          <div className="default-content-container flex items-center">
            <div className="default-content-container-inner marquee-wrapper relative overflow-hidden inline-block">
              <div className="marquee marquee-row-3">
                {brandLogoRow3.map((item) => (
                  <span key={item.id}>
                    <img
                      src={item.src}
                      alt={item.alt}
                      className="marqueelogo"
                    />
                  </span>
                ))}
              </div>
              <div className="marquee marquee-row-3">
                {brandLogoRow3.map((item) => (
                  <span key={item.id}>
                    <img
                      src={item.src}
                      alt={item.alt}
                      className="marqueelogo"
                    />
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Products;

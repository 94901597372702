import React, { useEffect, useState } from "react";
import About from "./Components/About";
import Banner from "./Components/Banner";
import Brands from "./Components/Brands";
import Footer from "./Components/Footer";
import LatestArrivals from "./Components/LatestArrivals";
import Navbar from "./Components/Navbar";
import Products from "./Components/Products";
import Contact from "./Components/Contact";
import { Fade } from "react-awesome-reveal";

import "./App.scss";
import "./breakpoints.scss";

const VIEWPORT_HEIGHT = window.innerHeight - 75;

function App() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    setVisible(
      (prevScrollPos > currentScrollPos &&
        prevScrollPos - currentScrollPos > VIEWPORT_HEIGHT) ||
        currentScrollPos < VIEWPORT_HEIGHT
    );

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible, handleScroll]);

  return (
    <div className="App">
      <Navbar visible={visible} />
      <Banner />
      <Fade triggerOnce delay={200}>
        <Brands />
      </Fade>
      <Fade triggerOnce delay={200}>
        <About />
      </Fade>
      <Products />
      <Fade triggerOnce delay={200}>
        <LatestArrivals />
      </Fade>
      <Fade triggerOnce delay={200}>
        <Contact />
      </Fade>
      <Footer />
    </div>
  );
}

export default App;

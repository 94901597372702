import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const resources = {
  English: {
    translation: {
      banner:
        "Get all your consumer electronics, computers, and mobile phones in one convenient place with us.",
      aboutsFirstDescription:
        "Whether you're a bulk seller or cater to retail shops, dealers, large chains, or online channels, we provide competitive sourcing of mobile phones, computers, and consumer electronics.",
      aboutsSecondDescription:
        "DigtlWorld operates as DigtlWorld LLC in Dubai and DigtlWorld Limited in Hong Kong, establishing its presence in both locations.",
      contact:
        "We'd love to hear from you! Feel free to reach out to us for any inquiries, feedback, or assistance. Our team at DigtlWorld is dedicated to providing excellent service and support. We appreciate your business and look forward to serving you.",
      imageItem1: "IT Products",
      imageItem2: "Cosmetics & Perfumes",
      imageItem3: "Networking and POS (Honeywell/ Zebra)",
      imageItem4: "Car Audio (Pioneer, Sony)",
      imageItem5: "Music- Yamaha",
      imageItem6: "Contact Lenses and Spectacles",
      imageItem7: "Bosch, Makita Metabo Power tools",
      imageItem8: "Automobile",
      copyrightText: "Copyright © 2023 DigtlWorld.com. All Rights Reserved.",
      topBrandsHeading: "TOP BRANDS",
      aboutsHeading1: "JOIN THE GLOBAL ECONOMY",
      aboutsHeading2: "OUR GLOBAL PRESENCE",
      brandsHeading: "CHOOSE BY BRAND",
      latestArrivalsHeading: "LATEST ARRIVALS",
      contactUsHeading: "CONTACT US",
      navbarItem1: "About",
      navbarItem2: "Products",
      navbarItem3: "Contact Us",
      phone: "Phone",
      email: "Email",
    },
  },
  Arabic: {
    translation: {
      banner:
        "احصل على جميع الأجهزة الإلكترونية الاستهلاكية وأجهزة الكمبيوتر والهواتف المحمولة في مكان واحد مناسب معنا.",
      aboutsFirstDescription:
        "سواء كنت بائعًا بالجملة أو تلبي احتياجات متاجر البيع بالتجزئة أو التجار أو السلاسل الكبيرة أو القنوات عبر الإنترنت ، فإننا نقدم مصادر تنافسية للهواتف المحمولة وأجهزة الكمبيوتر والإلكترونيات الاستهلاكية.",
      aboutsSecondDescription:
        "تعمل DigtlWorld كشركة DigtlWorld LLC في دبي و DigtlWorld Limited في هونغ كونغ ، لتأسيس وجودها في كلا الموقعين.",
      contact:
        "نحب أن نسمع منك! لا تتردد في التواصل معنا لأي استفسارات أو ملاحظات أو مساعدة. فريقنا في DigtlWorld مكرس لتقديم خدمة ودعم ممتازين. نحن نقدر عملك ونتطلع إلى خدمتك.",
      imageItem1: "منتجات تكنولوجيا المعلومات",
      imageItem2: "مستحضرات التجميل والعطور",
      imageItem3: "الشبكات ونقاط البيع (هانيويل / زيبرا)",
      imageItem4: "أنظمة صوت السيارة (بايونير ، سوني)",
      imageItem5: "موسيقى- ياماها",
      imageItem6: "العدسات اللاصقة والنظارات",
      imageItem7: "بوش ، ماكيتا ميتابو أدوات كهربائية",
      imageItem8: "سيارة",
      copyrightText: "حقوق النشر © 2023 DigtlWorld.com. كل الحقوق محفوظة.",
      topBrandsHeading: "ارقى الماركات",
      aboutsHeading1: "انضم إلى الاقتصاد العالمي",
      aboutsHeading2: "حضورنا العالمي",
      brandsHeading: "اختر الماركة",
      latestArrivalsHeading: "أحدث الوافدين",
      contactUsHeading: "اتصل بنا",
      navbarItem1: "بشأن",
      navbarItem2: "منتجات",
      navbarItem3: "اتصل بنا",
      phone: "هاتف",
      email: "بريد إلكتروني",
    },
  },
  Russian: {
    translation: {
      banner:
        "Получите всю свою бытовую электронику, компьютеры и мобильные телефоны в одном удобном месте вместе с нами.",
      aboutsFirstDescription:
        "Независимо от того, являетесь ли вы оптовым продавцом или обслуживаете розничные магазины, дилеров, крупные сети или онлайн-каналы, мы обеспечиваем конкурентоспособный поиск мобильных телефонов, компьютеров и бытовой электроники.",
      aboutsSecondDescription:
        "DigtlWorld работает как DigtlWorld LLC в Дубае и DigtlWorld Limited в Гонконге, обеспечивая свое присутствие в обоих местах.",
      contact:
        "Мы хотели бы услышать от вас! Не стесняйтесь обращаться к нам за любыми вопросами, отзывами или помощью. Наша команда в DigtlWorld стремится предоставить отличный сервис и поддержку. Мы ценим ваш бизнес и с нетерпением ждем возможности служить вам.",
      imageItem1: "Продукты в области информационных технологий",
      imageItem2: "Косметика и парфюмерия",
      imageItem3: "Создание сетей и POS-терминалов (Honeywell/ Zebra)",
      imageItem4: "Автомобильная аудиосистема (Pioneer, Sony)",
      imageItem5: "Музыка- Yamaha",
      imageItem6: "Контактные линзы и очки",
      imageItem7: "Электроинструменты Bosch, Makita Metabo",
      imageItem8: "Автомобиль",
      copyrightText:
        "Авторское право © 2023 DigtlWorld.com . все права защищены.",
      topBrandsHeading: "ЛУЧШИЕ БРЕНДЫ",
      aboutsHeading1: "ПРИСОЕДИНЯЙТЕСЬ К ГЛОБАЛЬНОЙ ЭКОНОМИКЕ",
      aboutsHeading2: "НАШЕ ГЛОБАЛЬНОЕ ПРИСУТСТВИЕ",
      brandsHeading: "ВЫБИРАЙТЕ ПО БРЕНДУ",
      latestArrivalsHeading: "ПОСЛЕДНИЕ ПОСТУПЛЕНИЯ",
      contactUsHeading: "связаться с нами",
      navbarItem1: "О",
      navbarItem2: "Продукты",
      navbarItem3: "связаться с нами",
      phone: "Телефон",
      email: "Электронная почта",
    },
  },
  Chinese: {
    translation: {
      banner:
        "通过我们，您可以将所有消费电子产品、电脑和手机集中到一个方便的地方。",
      aboutsFirstDescription:
        "无论您是大宗卖家还是零售店、经销商、大型连锁店或在线渠道，我们都提供有竞争力的手机、电脑和消费电子产品采购。",
      aboutsSecondDescription:
        "DigtlWorld 在迪拜以 DigtlWorld LLC 名义运营，在香港以 DigtlWorld Limited 名义运营，并在这两个地点开展业务。",
      contact:
        "我们很乐意听取您的意见！ 如果您有任何疑问、反馈或帮助，请随时联系我们。 我们 DigtlWorld 的团队致力于提供卓越的服务和支持。 我们感谢您的业务并期待为您服务。",
      imageItem1: "信息技术产品",
      imageItem2: "化妆品和香水",
      imageItem3: "网络和 POS (霍尼韦尔/斑马）",
      imageItem4: "汽车音响（先锋、索尼）",
      imageItem5: "音乐-雅马哈",
      imageItem6: "隐形眼镜和眼镜",
      imageItem7: "博世、牧田麦太保电动工具",
      imageItem8: "汽车",
      copyrightText: "版权所有 © 2023 DigtlWorld.com。 版权所有。",
      topBrandsHeading: "顶级品牌",
      aboutsHeading1: "加入全球经济",
      aboutsHeading2: "我们的全球业务",
      brandsHeading: "按品牌选择",
      latestArrivalsHeading: "最新到货",
      contactUsHeading: "联系我们",
      navbarItem1: "关于",
      navbarItem2: "产品",
      navbarItem3: "联系我们",
      phone: "电话",
      email: "电子邮件",
    },
  },
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "English",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;

import it from "./images/it-systems.png";
import cosmetics from "./images/skincare.png";
import networking from "./images/wireless-router.png";
import caraudio from "./images/sound-system.png";
import music from "./images/speaker.png";
import eye from "./images/spectacles.png";
import diy from "./images/drill.png";
import automobile from "./images/sedan.png";
// import sony from "./images/sony.jpg";

import jbl from "./images/jbl-vector-logo.png";
import sonylogo from "./images/sony-corporation-vector-logo.png";
import samsung from "./images/samsung-group-vector-logo.png";
import lg from "./images/lg-black-vector-logo.png";
import marshall from "./images/marshall-vector-logo.png";
import apple from "./images/37150-apple-logo.png";
import electrolux from "./images/Electrolux-01.png";
import xbox from "./images/1321-xbox-logo.png";
import ps from "./images/Playstation-01.png";
import nintendo from "./images/Nintendo-01.png";

import steamdeck from "./images/Steam_Deck_logo.png";
import delonghi from "./images/DeLonghi.png";
import kitchen from "./images/kitchenaid.png";
import babyliss from "./images/babyliss.png";
import dyson from "./images/Dyson.png";
import swiss from "./images/swiss.png";
import belkin from "./images/Belkin-Logo.png";
import hp from "./images/hp.jpeg";
import lenovo from "./images/Lenovo.jpg";
import oculus from "./images/Oculus.png";
import canon from "./images/canon.png";
import nikon from "./images/nikon.png";
import fuji from "./images/fuji.png";
import dji from "./images/dji.jpg";
import mi from "./images/mi.png";
import irobot from "./images/iRobot.png";
import nothing from "./images/Nothing.png";
import pixel from "./images/google-pixel.png";
import oneplus from "./images/oneplus.png";
import kenwood from "./images/kenwood.png";
import black from "./images/Black-Decker.jpg";
import shokz from "./images/shokz.png";
import senn from "./images/sennheiser.png";
import razer from "./images/razer.png";

const images = [
  {
    id: 1,
    src: it,
    alt: "Image 1",
    descKey: "imageItem1",
  },
  {
    id: 2,
    src: cosmetics,
    alt: "Image 2 ",
    descKey: "imageItem2",
  },
  {
    id: 3,
    src: networking,
    alt: "Image 3",
    descKey: "imageItem3",
  },
  {
    id: 4,
    src: caraudio,
    alt: "Image 4",
    descKey: "imageItem4",
  },
  {
    id: 5,
    src: music,
    alt: "Image 5",
    descKey: "imageItem5",
  },
  {
    id: 6,
    src: eye,
    alt: "Image 6",
    descKey: "imageItem6",
  },
  {
    id: 7,
    src: diy,
    alt: "Image 7",
    descKey: "imageItem7",
  },
  {
    id: 8,
    src: automobile,
    alt: "Image 8",
    descKey: "imageItem8",
  },
];

const brandLogoRow1 = [
  {
    id: 1,
    src: jbl,
    alt: "Image 1",
  },
  {
    id: 2,
    src: sonylogo,
    alt: "Image 1",
  },
  {
    id: 3,
    src: samsung,
    alt: "Image 1",
  },
  {
    id: 4,
    src: lg,
    alt: "Image 1",
  },
  {
    id: 5,
    src: marshall,
    alt: "Image 1",
  },
  {
    id: 6,
    src: apple,
    alt: "Image 1",
  },
  {
    id: 7,
    src: electrolux,
    alt: "Image 1",
  },
  {
    id: 8,
    src: xbox,
    alt: "Image 1",
  },
  {
    id: 9,
    src: ps,
    alt: "Image 1",
  },
  {
    id: 10,
    src: nintendo,
    alt: "Image 1",
  },
];

const brandLogoRow2 = [
  {
    id: 1,
    src: steamdeck,
    alt: "Image 1",
  },
  {
    id: 2,
    src: delonghi,
    alt: "Image 1",
  },
  {
    id: 3,
    src: kitchen,
    alt: "Image 1",
  },
  {
    id: 4,
    src: babyliss,
    alt: "Image 1",
  },
  {
    id: 5,
    src: dyson,
    alt: "Image 1",
  },
  {
    id: 6,
    src: swiss,
    alt: "Image 1",
  },
  {
    id: 7,
    src: belkin,
    alt: "Image 1",
  },
  {
    id: 8,
    src: hp,
    alt: "Image 1",
  },
  {
    id: 9,
    src: lenovo,
    alt: "Image 1",
  },
  {
    id: 10,
    src: oculus,
    alt: "Image 1",
  },
  {
    id: 11,
    src: razer,
    alt: "Image 1",
  },
];

const brandLogoRow3 = [
  {
    id: 1,
    src: canon,
    alt: "Image 1",
  },
  {
    id: 2,
    src: nikon,
    alt: "Image 1",
  },
  {
    id: 3,
    src: fuji,
    alt: "Image 1",
  },
  {
    id: 4,
    src: dji,
    alt: "Image 1",
  },
  {
    id: 5,
    src: mi,
    alt: "Image 1",
  },
  {
    id: 6,
    src: irobot,
    alt: "Image 1",
  },
  {
    id: 7,
    src: nothing,
    alt: "Image 1",
  },
  {
    id: 8,
    src: pixel,
    alt: "Image 1",
  },
  {
    id: 9,
    src: oneplus,
    alt: "Image 1",
  },
  {
    id: 10,
    src: kenwood,
    alt: "Image 1",
  },
  {
    id: 11,
    src: black,
    alt: "Image 1",
  },
  {
    id: 12,
    src: shokz,
    alt: "Image 1",
  },
  {
    id: 13,
    src: senn,
    alt: "Image 1",
  },
];

export { images, brandLogoRow1, brandLogoRow2, brandLogoRow3 };

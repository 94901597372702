import React from "react";
import "./About.scss";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="about wrapper">
      <div className="about-category">
        <div className="about-title">{t("aboutsHeading1")}</div>
        <div className="about-description">{t("aboutsFirstDescription")}</div>
      </div>
      <div className="about-category">
        <div className="about-title">{t("aboutsHeading2")}</div>
        <div className="about-description">{t("aboutsSecondDescription")}</div>
      </div>
    </section>
  );
};

export default About;
